/// IMPORTS

// Openlayers
import 'ol/ol.css'
import Map from 'ol/Map'
import View from 'ol/View'
import Projection from 'ol/proj/Projection.js'
import VectorTileSource from 'ol/source/VectorTile'
import VectorTileLayer from 'ol/layer/VectorTile'
import TileLayer from 'ol/layer/Tile'
import TileWMS from 'ol/source/TileWMS'
import WMTSSource from 'ol/source/WMTS'
import WMTS from 'ol/tilegrid/WMTS'
import MVT from 'ol/format/MVT'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import GeoJSON from 'ol/format/GeoJSON'
import { tile, bbox as bboxStrategy, all } from 'ol/loadingstrategy'
import { Style, Fill, Stroke, Circle, Image, Text, Icon } from 'ol/style'
import Point from 'ol/geom/Point'
import Cluster from 'ol/source/Cluster'
import { Heatmap as HeatmapLayer } from 'ol/layer'
import Overlay from 'ol/Overlay'
import Select from 'ol/interaction/Select'

// Bootstrap
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.css'

// Fonts
import '@fontsource/ibm-plex-sans/400.css'
import '@fontsource/ibm-plex-sans/400-italic.css'

// Awecomplete
import 'awesomplete/awesomplete.js'
import 'awesomplete/awesomplete.css'

// App
import { Progress } from './progress'

// Custom CSS
import './assets/css/styles.scss'

/// DEFAULTS

// Projection
const projection = new Projection({
  code: 'EPSG:2056',
  units: 'm'
})
const zoom = 4
const center = [2681828, 1248384] // Zürich //[2687126,1261849]; //embrach
const extent = [2641167.0, 1200659.6, 2738766.2, 1309085.5]
const resolutions = [440.61341624303077, 220.30670812151538, 110.15335406075769, 55.076677030378846, 27.538338515189423, 13.769169257594712, 6.884584628797356, 3.442292314398678, 1.721146157199339, 0.8605730785996695, 0.4302865392998347, 0.2151432696499174, 0.1075716348249587]
const gridsetName = 'COLD:2056'
const gridNames = ['COLD:2056:0', 'COLD:2056:1', 'COLD:2056:2', 'COLD:2056:3', 'COLD:2056:4', 'COLD:2056:5', 'COLD:2056:6', 'COLD:2056:7', 'COLD:2056:8', 'COLD:2056:9', 'COLD:2056:10', 'COLD:2056:11', 'COLD:2056:12']
const gridNamesPNG = ['COLD:2056:0', 'COLD:2056:1', 'COLD:2056:2', 'COLD:2056:3', 'COLD:2056:4', 'COLD:2056:5', 'COLD:2056:6', 'COLD:2056:7', 'COLD:2056:8', 'COLD:2056:9']
const baseUrl = 'https://geo.coldpressed.ch/geoserver/gwc/service/wmts'
const format = 'application/vnd.mapbox-vector-tile'

const progress = new Progress(document.getElementById('progress'))

/// SOURCES

const layer_2056_2022_bodenbedeckung_png = new TileLayer({
  minZoom: 1,
  maxZoom: 8,
  source: new TileWMS({
    url: 'https://geo.coldpressed.ch/geoserver/kanton-zuerich/wms',
    params: {
      FORMAT: 'image/png',
      VERSION: '1.1.1',
      tiled: true,
      STYLES: '',
      LAYERS: 'kanton-zuerich:2056_2022_bodenbedeckung_401_1',
      exceptions: 'application/vnd.ogc.se_inimage',
      tilesOrigin: 2669315.3553862376 + ',' + 1223890.61409
    }
  })
})

function createMVTRequestParams (wmtslayer) {
  const params = {
    TILED: 'TRUE',
    REQUEST: 'GetTile',
    SERVICE: 'WMTS',
    VERSION: '1.0.0',
    LAYER: wmtslayer,
    STYLE: '',
    TILEMATRIX: gridsetName + ':{z}',
    TILEMATRIXSET: gridsetName,
    FORMAT: format,
    TILECOL: '{x}',
    TILEROW: '{y}'
  }
  return params
}

function MVTSource (wmtslayer, serverstyle) {
  let url = baseUrl + '?'
  const params = createMVTRequestParams(wmtslayer)
  for (const param in params) {
    url = url + param + '=' + params[param] + '&'
  }
  url = url.slice(0, -1)

  const source = new VectorTileSource({
    url,
    style: 'T45',
    format: new MVT({}),
    projection,
    tilePixelRatio: 1,
    zDirection: -1, // do not blur!
    tileGrid: new WMTS({
      tileSize: [512, 512],
      origin: [2485014.052451379, 1299782.763494124],
      resolutions,
      matrixIds: gridNames
    }),
    wrapX: true,
    overlaps: true
  })
  return source
}

let frist = new Date()

const offset = frist.getTimezoneOffset()
frist = new Date(frist.getTime() - (offset * 60 * 1000) - (20 * 86400000))
const after = frist.toISOString().split('T')[0] + 'T00:00:00'

let filteredsource = new VectorSource({})

function getFilteredSource (typename, filter) {
  filteredsource = new VectorSource({
    format: new GeoJSON(),
    url: function (extent) {
      return (
        'https://geo.coldpressed.ch/geoserver/ows?service=wfs&version=1.1.0&request=GetFeature' +
          '&typename=' + typename + '&' +
          'outputFormat=application/json&srsname=EPSG:2056&' +
          '&SORTBY=published DESC&CQL_FILTER=' + filter +
          ''
      )
    },
    strategy: all
  })
  return filteredsource
}

const source_bpzh01 = getFilteredSource('kanton-zuerich:ktzh_bpzh01_full', 'published AFTER ' + after)

const source_strassenverzeichnis_schweiz_linien = new VectorSource({
  format: new GeoJSON(),
  url: function (extent) {
    return (
      'https://geo.coldpressed.ch/geoserver/ows?service=wfs&version=1.1.0&request=GetFeature' +
      '&typename=schweiz:strassenverzeichnis_schweiz_linien&' +
      'outputFormat=application/json&srsname=EPSG:2056&' +
      'bbox=' +
      extent.join(',') +
      ',EPSG:2056'
    )
  },
  strategy: bboxStrategy
})

const source_2056_2022_ortsnamen_403_2 = new VectorSource({
  format: new GeoJSON(),
  url: function (extent) {
    return (
      'https://geo.coldpressed.ch/geoserver/ows?service=wfs&version=1.1.0&request=GetFeature' +
      '&typename=kanton-zuerich:2056_2022_ortsnamen_403_2&' +
      'outputFormat=application/json&srsname=EPSG:2056&' +
      'bbox=' +
      extent.join(',') +
      ',EPSG:2056'
    )
  },
  strategy: bboxStrategy
})

const source_2056_2022_ortsnamen_gemeindedaten = new VectorSource({
  format: new GeoJSON(),
  url: function (extent) {
    return (
      'https://geo.coldpressed.ch/geoserver/ows?service=wfs&version=1.1.0&request=GetFeature' +
      '&typename=kanton-zuerich:2056_2022_ortsnamen_gemeindedaten&' +
      'outputFormat=application/json&srsname=EPSG:2056&' +
      'bbox=' +
      extent.join(',') +
      ',EPSG:2056'
    )
  },
  strategy: bboxStrategy
})

const source_2056_2022_hausnummern = new VectorSource({
  format: new GeoJSON(),
  url: function (extent) {
    return (
      'https://geo.coldpressed.ch/geoserver/ows?service=wfs&version=1.1.0&request=GetFeature' +
      '&typename=kanton-zuerich:2056_2022_hausnummern_406_4&' +
      'outputFormat=application/json&srsname=EPSG:2056&' +
      'bbox=' +
      extent.join(',') +
      ',EPSG:2056'
    )
  },
  strategy: bboxStrategy
})

let nullgeometry = 0

const source_bpzh01_cluster = new Cluster({
  distance: 50,
  geometryFunction: function (feature) {
    if (feature.getGeometry() != null) {
      if (feature.getGeometry().getType() === 'MultiPoint') {
        return new Point(feature.getGeometry().getFlatCoordinates(0))
      } else if (feature.getGeometry().getType() === 'Point') {
        return new Point(feature.getGeometry().getFlatCoordinates(0))
      } else {
        nullgeometry += 1
      }
    }
  },
  source: source_bpzh01
})

const layer_heatmap = new HeatmapLayer({
  name: 'layer_heatmap',
  source: source_bpzh01,
  blur: 30,
  radius: 10,
  weight: function (feature) {
    const weight = 1
    return weight
  },
  gradient: ['rgba(0,0,0)', 'rgba(0,0,0)', 'rgba(0,0,0)', 'rgba(0,0,0)', 'rgba(0,0,0)'],
  minZoom: 2,
  maxZoom: 13
})

const styleCache = {}

const style_cluster = function (feature) {
  const size = feature.get('features').length
  let radius = size * 3
  let count = ''
  if (radius < 12) {
    radius = 12
  }
  if (size > 1) {
    count = size.toString()
  }
  let style = styleCache[size]
  if (!style) {
    style = new Style({
      image: new Circle({
        radius,
        silent: true,
        stroke: new Stroke({
          color: '#fff'
        }),
        fill: new Fill({
          color: '#000'
        })
      }),
      text: new Text({
        text: count,
        font: 'normal 11px "IBM Plex Sans", "sans-serif"',
        fill: new Fill({
          color: '#FFF'
        })
      })
    })
    styleCache[size] = style
  }
  return style
}

const layer_bpzh01_clusters = new VectorLayer({
  name: 'layer_bpzh01_clusters',
  minZoom: 4,
  maxZoom: 8,
  source: source_bpzh01_cluster,
  style: style_cluster
})

const style_bpzh01_single = function (feature) {
  let style = new Style({})
  style = new Style({
    image: new Circle({
      radius: 12,
      silent: true,
      stroke: new Stroke({
        color: '#fff'
      }),
      fill: new Fill({
        color: '#000'
      })
    })
  })
  return style
}

const layer_bpzh01_single = new VectorLayer({
  name: 'layer_bpzh01_single',
  minZoom: 8,
  maxZoom: 13,
  source: source_bpzh01,
  style: style_bpzh01_single
})

function createFill (fillcolor) {
  const fill = new Fill({
    color: fillcolor
  })
  return fill
}

const stroke = new Stroke()

function createStroke (strokecolor, strokestyle, strokewidth) {
  if (strokestyle === 'dashed') {
    var stroke = new Stroke({
      color: strokecolor,
      lineDash: [8, 8],
      width: strokewidth
    })
  } else if (strokestyle === 'dotted') {
    stroke = new Stroke({
      color: strokecolor,
      lineDash: [1, 3],
      width: strokewidth
    })
  } else {
    stroke = new Stroke({
      color: strokecolor,
      width: strokewidth
    })
  }
  return stroke
}

function createStyle (fillcolor, strokecolor, strokestyle, strokewidth) {
  const style = new Style({
    fill: createFill(fillcolor),
    stroke: createStroke(strokecolor, strokestyle, strokewidth)
  })
  return style
}

const style_wald = function (feature) {
  let style = new Style({})
  const id = feature.get('artzh')
  if (id.indexOf('Bestockte') >= 0 ||
      id.indexOf('Wald') >= 0) {
    style = createStyle('rgba(170, 198, 134,0.5)', 'rgba(170, 198, 134,0.5)', 'normal', '0.01')
  }
  return style
}

const style_gebaeude = function (feature) {
  let style = new Style({})
  const id = feature.get('art') // nicht artzh, wegen Begriff 'Gebäude'
  if (id.indexOf('Gebäude') >= 0) {
    style = createStyle('rgba(78, 78, 78, 0.9)', 'rgba(78, 78, 78, 0.9)', 'normal', '0.01')
  }
  return style
}

const style_humusiert = function (feature) {
  let style = new Style({})
  const id = feature.get('artzh')
  if (id.indexOf('Andere humusierte') >= 0 ||
      id === 'Acker, Wiese, Weide' ||
      id === 'Hoch-, Flachmoor' ||
      id === 'Intensivkultur') {
    style = createStyle('rgba(175, 209, 29, 0.10 )', 'rgba(175, 209, 29, 0.10)', 'normal', '0.01')
  }
  return style
}

const style_humusiert_garten = function (feature) {
  let style = new Style({})
  const id = feature.get('artzh')
  if (id === 'Hausumschwung humusiert' ||
      id === 'Parkanlage' ||
      id === 'Reben' ||
      id.indexOf('Schilf') >= 0 || // Schilfgürtel
      id === 'Sportanlage humusiert' ||
      id === 'Verkehrsinsel humusiert'
  ) {
    style = createStyle('rgba(175, 209, 29, 0.20)', 'rgba(155, 189, 9, 0.40)', 'dotted', '1')
  }
  return style
}

const style_gewaesser = function (feature) {
  let style = new Style({})
  const id = feature.get('artzh')
  if (id.indexOf('Fliess') >= 0 ||
      id === 'See, Weiher' ||
      id === 'Wasserbecken'
  ) {
    style = createStyle('rgba(182, 219, 242, 0.5)', 'rgba(182, 219, 242, 0.5)', 'normal', '0.01')
  }
  return style
}

const style_befestigt = function (feature) {
  let style = new Style({})
  const id = feature.get('artzh')
  if (id.indexOf('befestigt') >= 0 ||
      id === 'Fels' ||
      id === 'Flugplatz' ||
      id === 'Parkplatz'
  ) {
    style = createStyle('rgba(235, 235, 215, 0.4)', 'rgba(215, 215, 195, 0.8)', 'dotted', '1')
  }
  return style
}

const style_strasse = function (feature) {
  let style = new Style({})
  const id = feature.get('artzh')
  if (id === 'Strasse, Weg' ||
      id === 'Trottoir') {
    style = createStyle('rgba(241, 241, 241, 1)', 'rgba(241, 241, 241, 1)', 'normal', '0.01')
  }
  return style
}

const style_nebenstrasse = function (feature) {
  let style = new Style({})
  const id = feature.get('artzh')
  if (id === 'Veloweg, Fussweg' ||
      id === 'Landwirtschaftsstrasse' ||
      id === 'Waldstrasse') {
    style = createStyle('rgba(241, 241, 241, 1)', 'rgba(241, 241, 241, 1)', 'dotted', '1')
  }
  return style
}

const style_bahngebiet_abbau_geroell = function (feature) {
  let style = new Style({})
  const id = feature.get('artzh')
  if (id === 'Abbau' ||
      id === 'Bahngebiet' ||
      id.indexOf('Sand') >= 0 || // includes 'geröll'
      id === 'Deponie') {
    style = createStyle('rgba(216, 194, 106, 0.38 )', 'rgba(216, 194, 106, 0.38 )', 'normal', '0.01')
  }
  return style
}

const style_streetlabel = new Style({
  text: new Text({
    font: 'normal 11px "IBM Plex Sans", "sans-serif"',
    placement: 'line',
    fill: new Fill({
      color: 'rgba(28, 28, 28, 0.9)'
    })
  })
})

const style_ortsnamen_gemeindedaten = function (feature) {
  let style = new Style({})
  const text = feature.get('dplzname')
  const size = feature.get('einwohne_1')
  const region = feature.get('planungsre')
  const zoom = map.getView().getZoom()
  if (size >= 0 && zoom > 4) {
    style = new Style({
      text: new Text({
        text,
        font: 'italic 13px "IBM Plex Sans", "sans-serif"',
        placement: 'point',
        fill: new Fill({
          color: 'rgba(28, 28, 28, 1)'
        }),
        backgroundFill: new Fill({
          color: 'rgba(255, 255, 255, 1)'
        }),
        backgroundStroke: new Stroke({
          color: 'rgba(0, 0, 0, 0)'
        }),
        justify: 'center',
        padding: [0, 3, 0, 4]
      })
    })
  } else if (size >= 3000 && zoom === 4 || region === 'Unterland' || region === 'Weinland') {
    style = new Style({
      text: new Text({
        text,
        font: 'italic 12px "IBM Plex Sans", "sans-serif"',
        placement: 'point',
        fill: new Fill({
          color: 'rgba(28, 28, 28, 1)'
        }),
        backgroundFill: new Fill({
          color: 'rgba(255, 255, 255, 1)'
        }),
        backgroundStroke: new Stroke({
          color: 'rgba(0, 0, 0, 0)'
        }),
        justify: 'center',
        padding: [0, 3, 0, 4]
      })
    })
  } else if (size >= 10000 && zoom === 3) {
    style = new Style({
      text: new Text({
        text,
        font: 'italic 11px "IBM Plex Sans", "sans-serif"',
        placement: 'point',
        fill: new Fill({
          color: 'rgba(28, 28, 28, 1)'
        }),
        backgroundFill: new Fill({
          color: 'rgba(255, 255, 255, 1)'
        }),
        backgroundStroke: new Stroke({
          color: 'rgba(0, 0, 0, 0)'
        }),
        justify: 'center',
        padding: [0, 3, 0, 4]
      })
    })
  } else if (zoom === 6) {
    style = new Style({
      text: new Text({
        text,
        font: 'italic 13px "IBM Plex Sans", "sans-serif"',
        placement: 'point',
        fill: new Fill({
          color: 'rgba(28, 28, 28, 1)'
        }),
        backgroundFill: new Fill({
          color: 'rgba(255, 255, 255, 1)'
        }),
        backgroundStroke: new Stroke({
          color: 'rgba(0, 0, 0, 0)'
        }),
        justify: 'center',
        padding: [0, 3, 0, 4]
      })
    })
  }
  return style
}

const style_ortsnamen = function (feature) {
  let style = new Style({})
  const kategorie = feature.get('kategorie')
  const text = feature.get('name')
  const zoom = map.getView().getZoom()
  if (kategorie === 'Ortsname') {
    style = new Style({
      text: new Text({
        text,
        font: 'italic 13px "IBM Plex Sans", "sans-serif"',
        placement: 'point',
        fill: new Fill({
          color: 'rgba(28, 28, 28, 1)'
        }),
        backgroundFill: new Fill({
          color: 'rgba(255, 255, 255, 1)'
        }),
        backgroundStroke: new Stroke({
          color: 'rgba(0, 0, 0, 0)'
        }),
        justify: 'center',
        padding: [0, 3, 0, 4]
      })
    })
  }
  return style
}

const style_hausnummern = function (feature) {
  let style = new Style({})
  const text = feature.get('beschriftu')
  const zoom = map.getView().getZoom()
  style = new Style({
    text: new Text({
      text,
      font: 'normal 10px "IBM Plex Sans", "sans-serif"',
      placement: 'point',
      fill: new Fill({
        color: 'rgba(28, 28, 28, 1)'
      }),
      backgroundFill: new Fill({
        color: 'rgba(255, 255, 255, 1)'
      }),
      backgroundStroke: new Stroke({
        color: 'rgba(0, 0, 0, 0)'
      }),
      justify: 'center',
      padding: [0, 3, 0, 4]
    })
  })
  return style
}

const style_red = new Style({
  image: new Circle({
    radius: 12,
    silent: true,
    stroke: new Stroke({
      color: '#fff'
    }),
    fill: new Fill({
      color: '#f00'
    })
  })
})

// SOURCES

const source_2056_2022_bodenbedeckung_401_1 = MVTSource('kanton-zuerich:2056_2022_bodenbedeckung_401_1')
const source_2056_2022_gemeindegrenzen = MVTSource('kanton-zuerich:2056_2022_gemeinden_278_3')

/// LAYERS

const layer_2056_2022_gebaeude = new VectorTileLayer({
  source: source_2056_2022_bodenbedeckung_401_1,
  style: style_gebaeude,
  minZoom: 8,
  maxZoom: 13
})

const layer_2056_2022_wald = new VectorTileLayer({
  source: source_2056_2022_bodenbedeckung_401_1,
  style: style_wald,
  minZoom: 8,
  maxZoom: 13
})

const layer_2056_2022_humusiert = new VectorTileLayer({
  source: source_2056_2022_bodenbedeckung_401_1,
  style: style_humusiert,
  minZoom: 8,
  maxZoom: 13
})

const layer_2056_2022_humusiert_garten = new VectorTileLayer({
  source: source_2056_2022_bodenbedeckung_401_1,
  style: style_humusiert_garten,
  minZoom: 8,
  maxZoom: 13
})

const layer_2056_2022_gewaesser = new VectorTileLayer({
  source: source_2056_2022_bodenbedeckung_401_1,
  style: style_gewaesser,
  minZoom: 8,
  maxZoom: 13
})

const layer_2056_2022_befestigt = new VectorTileLayer({
  source: source_2056_2022_bodenbedeckung_401_1,
  style: style_befestigt,
  minZoom: 8,
  maxZoom: 13
})

const layer_2056_2022_strasse = new VectorTileLayer({
  source: source_2056_2022_bodenbedeckung_401_1,
  style: style_strasse,
  minZoom: 8,
  maxZoom: 13
})

const layer_2056_2022_nebenstrasse = new VectorTileLayer({
  source: source_2056_2022_bodenbedeckung_401_1,
  style: style_nebenstrasse,
  minZoom: 8,
  maxZoom: 13
})

const layer_2056_2022_bahngebiet_abbau_geroell = new VectorTileLayer({
  source: source_2056_2022_bodenbedeckung_401_1,
  style: style_bahngebiet_abbau_geroell,
  minZoom: 8,
  maxZoom: 13
})

const layer_strassenverzeichnis_schweiz_linien = new VectorLayer({
  declutter: true,
  source: source_strassenverzeichnis_schweiz_linien,
  style: function (feature) {
    style_streetlabel.getText().setText(feature.get('stn_text'))
    return style_streetlabel
  },
  minZoom: 8,
  maxZoom: 13
})

const layer_2056_2022_ortsnamen_403_2 = new VectorLayer({
  // declutter: true,
  source: source_2056_2022_ortsnamen_403_2,
  style: style_ortsnamen,
  minZoom: 6,
  maxZoom: 13
})

const layer_2056_2022_ortsnamen_gemeindedaten = new VectorLayer({
  // declutter: true,
  source: source_2056_2022_ortsnamen_gemeindedaten,
  style: style_ortsnamen_gemeindedaten,
  minZoom: 2,
  maxZoom: 6
})

const layer_2056_2022_hausnummern = new VectorLayer({
  // declutter: true,
  source: source_2056_2022_hausnummern,
  style: style_hausnummern,
  minZoom: 10,
  maxZoom: 13
})

const layer_2056_2022_gemeindegrenzen = new VectorTileLayer({
  source: source_2056_2022_gemeindegrenzen,
  style: createStyle('rgba(0,0,0,0)', 'rgba(230,30,230,.2)', 'dashed', '1.8'),
  minZoom: 5,
  maxZoom: 13
})

/**
 * Elements that make up the popup.
 */
const container = document.getElementById('popup')
const content = document.getElementById('popup-content')
const closer = document.getElementById('popup-closer')

/**
  * Create an overlay to anchor the popup to the map.
  */
const overlay = new Overlay({
  element: container,
  autoPan: {
    animation: {
      duration: 250
    }
  }
})

/**
  * Add a click handler to hide the popup.
  * @return {boolean} Don't follow the href.
  */
closer.onclick = function (feature) {
  overlay.setPosition(undefined)
  closer.blur()
  return false
}

// VIEW

const view = new View({
  center,
  zoom,
  resolutions,
  constrainResolution: true,
  projection,
  extent // plus 1 zoom out for fit to view
})

layer_2056_2022_bodenbedeckung_png.on('postrender', function () {
  progress.addLoading()
})

layer_2056_2022_bodenbedeckung_png.on('postrender', function () {
  progress.addLoaded()
})

var map = new Map({
  layers: [
    layer_2056_2022_bodenbedeckung_png,
    layer_2056_2022_wald,
    layer_2056_2022_gebaeude,
    layer_2056_2022_humusiert,
    layer_2056_2022_humusiert_garten,
    layer_2056_2022_gewaesser,
    layer_2056_2022_befestigt,
    layer_2056_2022_strasse,
    layer_2056_2022_nebenstrasse,
    layer_2056_2022_bahngebiet_abbau_geroell,
    layer_2056_2022_gemeindegrenzen,
    layer_strassenverzeichnis_schweiz_linien,
    layer_2056_2022_hausnummern,
    layer_2056_2022_ortsnamen_gemeindedaten,
    layer_2056_2022_ortsnamen_403_2,
    layer_heatmap,
    layer_bpzh01_single,
    layer_bpzh01_clusters

  ],
  overlays: [overlay],
  target: 'map',
  view
})

map.on('moveend', function (event) {
  const zoomfactor = document.getElementById('zoomFactor')
  zoomfactor.innerHTML = map.getView().getZoom()
  // var zoomfactorhelp = document.getElementById('zoomFactorHelp');
  const coords = document.getElementById('coords')
  const coordsraw = map.getView().getCenter()
  coords.innerHTML = Math.round(coordsraw[0]) + ' / ' + Math.round(coordsraw[1])

  // var taste_beschriftungen = document.getElementById("beschriftungen");
  // Set buttons inactive
  if (map.getView().getZoom() <= 9) {
    // taste_beschriftungen.disabled = true;
  } else {
  }
})

let geom; let publication_id; let description; let published; let swissdate; let address = ''

const select = new Select({
  layers: [layer_bpzh01_single],
  style: style_red
})
map.addInteraction(select)

map.on('click', function (evt) {
  const coordinate = evt.coordinate
  const x = Math.round(evt.coordinate[0] * 100) / 100
  const y = Math.round(evt.coordinate[1] * 100) / 100
  let layername = ''

  const feature = map.forEachFeatureAtPixel(evt.pixel,
    function (feature, layer) {
      layername = layer.get('name')
      return feature
    })

  if (feature) {
    const features = feature.get('features')
    if (feature !== undefined && isCluster(feature) && features !== undefined) {
      container.style.display = 'block'
      content.innerHTML = ''

      // is a cluster, so loop through all the underlying features
      for (let i = 0; i < features.length; i++) {
        publication_id = features[i].get('publication_id')
        published = features[i].get('published').substring(0, 10)
        description = features[i].get('description')
        address = features[i].get('town') + ', ' + features[i].get('street') + ' ' + features[i].get('streetnr')
        var publdate = new Date(published)
        const publgettime = publdate.getTime()
        var day = publdate.getDate()
        var month = publdate.getMonth() + 1 // getMonth() returns month from 0 to 11
        var year = publdate.getFullYear()
        swissdate = `${day}. ${month}. ${year}`
        content.innerHTML += '<div id="' + publgettime + '"<p></p><small style="color:red">' + swissdate + '</small>' +
            '<p class="fw-bold">' + address + '</p>' +
            '<p>' + description + '' +
            '<br><small><a href="https://amtsblatt.zh.ch/#!/search/publications/detail/' + publication_id + '">Amtsblatt</a>' +
            '<a class="mx-2"href="https://maps.zh.ch?AVfarbigZH&scale=1000&x=' + x + '&y=' + y + '&srid=2056&markers=ring">Maps ZH</a></small></p></div>'
      };
      sortDivs()
      overlay.setPosition(coordinate)
    } else if (layername === 'layer_bpzh01_single') {
      container.style.display = 'block'
      content.innerHTML = ''
      publication_id = feature.get('publication_id')
      published = feature.get('published').substring(0, 10)
      description = feature.get('description')
      address = feature.get('town') + ', ' + feature.get('street') + ' ' + feature.get('streetnr')
      var publdate = new Date(published)
      var day = publdate.getDate() + 1
      var month = publdate.getMonth() + 1 // getMonth() returns month from 0 to 11
      var year = publdate.getFullYear()
      swissdate = `${day}. ${month}. ${year}`
      content.innerHTML = '<small style="color:red">' + swissdate + '</small>' +
          '<p class="fw-bold">' + address + '</p>' +
          '<p>' + description + '' +
          '<br><small><a href="https://amtsblatt.zh.ch/#!/search/publications/detail/' + publication_id + '">Amtsblatt</a>' +
          '<a class="mx-2"href="https://maps.zh.ch?AVfarbigZH&scale=400&x=' + x + '&y=' + y + '&srid=2056&markers=ring">Maps ZH</a></small></p>'
      overlay.setPosition(coordinate)
    }
  }
})

/**
 * Add autocompletion to the location search input
 */

let list; let coordslist = []

function initSearchAutocomplete () {
  const input = document.querySelector('#search')

  if (!input) {
    return
  }

  const awesomplete = new Awesomplete(input, { tabSelect: true, minChars: 3, maxItems: 30 })

  /**
	 * Search request
	 */
  function requestResults () {
    const endpointURL = 'https://api.coldpressed.ch/search/?search=' + input.value + '%' // LIKE text%

    if (input.value.length > 2) {
      const request = new XMLHttpRequest()
      request.open('GET', endpointURL, true)

      request.onload = () => {
        list = []
        if (request.status === 200) {
          list = JSON.parse(request.responseText).map(function (item) {
            return item.strname + ' ' + item.deinr + ', ' + item.dplzname
          })
          coordslist = JSON.parse(request.responseText).map(function (item) {
            return [item.gkode, item.gkodn]
          })
          awesomplete.list = list
        }
      }

      request.send()
    }
  }

  input.addEventListener('keyup', requestResults)
}

initSearchAutocomplete()

const searchgo = document.getElementById('searchgo')
searchgo.addEventListener('click', function (event) {
  const input = document.querySelector('#search').value
  const arrayindex = list.indexOf(input)
  console.log(coordslist[arrayindex])
  if (input && arrayindex > 0) {
    view.animate({
      center: coordslist[arrayindex],
      zoom: 11,
      duration: 100
    })
  }
  document.querySelector('#search').value = '';
  input.modal('hide');
})

// FILTER

const filterswitchRefresh = document.getElementById('filterrefresh')

let source_changed = getFilteredSource('kanton-zuerich:ktzh_bpzh01_full', 'published AFTER 2022-03-01T00:00:00')
const source_default = getFilteredSource('kanton-zuerich:ktzh_bpzh01_full', 'published AFTER ' + after)

let heatmap = new HeatmapLayer({})

const createHeatmapLayer = function (name, source, blur, radius, gradient) {
  heatmap = new HeatmapLayer({
    name,
    source,
    blur,
    radius,
    weight: function (feature) {
      const weight = 1
      return weight
    },
    gradient,
    minZoom: 2,
    maxZoom: 13
  })
  return heatmap
}

let clustersource = new Cluster({})
let clusterlayer = new VectorLayer({})

const createClusterSource = function (source) {
  clustersource = new Cluster({
    distance: 50,
    geometryFunction: function (feature) {
      if (feature.getGeometry() != null) {
        if (feature.getGeometry().getType() === 'MultiPoint') {
          return new Point(feature.getGeometry().getFlatCoordinates(0))
        } else if (feature.getGeometry().getType() === 'Point') {
          return new Point(feature.getGeometry().getFlatCoordinates(0))
        } else {
          nullgeometry += 1
        }
      }
    },
    source
  })
  return clustersource
}

const createClusterLayer = function (name, source) {
  clusterlayer = new VectorLayer({
    name,
    minZoom: 4,
    maxZoom: 8,
    source: createClusterSource(source),
    style: style_cluster
  })
  return clusterlayer
}

let singlelayer = new VectorLayer({})

const createSingleLayer = function (layername, source) {
  singlelayer = new VectorLayer({
    name: layername,
    minZoom: 8,
    maxZoom: 13,
    source,
    style: style_bpzh01_single
  })
  return singlelayer
}

function layerRemove (layername) {
  map.getLayers().forEach(layer => {
    if (layer && layer.get('name') === layername) {
      map.removeLayer(layer)
    }
  })
}

filterswitchRefresh.addEventListener('click', function (event) {
  if (filterswitchRefresh) {
    const filterStart = document.getElementById('filterstart').value + 'T00:00:00'
    const filterEnd = document.getElementById('filterend').value + 'T23:59:59'
    source_changed = getFilteredSource('kanton-zuerich:ktzh_bpzh01_full', 'published >= ' + filterStart + ' AND published <= ' + filterEnd)
    layerRemove('layer_heatmap')
    layerRemove('layer_bpzh01_clusters')
    layerRemove('layer_bpzh01_single')
    const layer_heatmap = createHeatmapLayer('layer_heatmap', source_changed, 30, 10, ['rgba(0,0,0)', 'rgba(0,0,0)', 'rgba(0,0,0)', 'rgba(0,0,0)', 'rgba(0,0,0)'])
    const layer_bpzh01_clusters = createClusterLayer('layer_bpzh01_clusters', source_changed)
    const layer_bpzh01_single = createSingleLayer('layer_bpzh01_single', source_changed)
    map.addLayer(layer_heatmap)
    map.addLayer(layer_bpzh01_clusters)
    map.addLayer(layer_bpzh01_single)
  } else {
    layerRemove('layer_heatmap')
    layerRemove('layer_bpzh01_clusters')
    layerRemove('layer_bpzh01_single')
    const layer_heatmap = createHeatmapLayer('layer_heatmap', source_default, 30, 10, ['rgba(0,0,0)', 'rgba(0,0,0)', 'rgba(0,0,0)', 'rgba(0,0,0)', 'rgba(0,0,0)'])
    const layer_bpzh01_clusters = createClusterLayer('layer_bpzh01_clusters', source_default)
    const layer_bpzh01_single = createSingleLayer('layer_bpzh01_single', source_default)
    map.addLayer(layer_heatmap)
    map.addLayer(layer_bpzh01_clusters)
    map.addLayer(layer_bpzh01_single)
  }
})

const startdate = frist.toISOString().split('T')[0]
const enddate = new Date().toISOString().split('T')[0]
document.getElementById('filterstart').value = startdate
document.getElementById('filterend').value = enddate

// HELPERS

// Check isCluster

function isCluster (feature) {
  if (!feature || !feature.get('features')) {
    return false
  }
  return feature.get('features').length >= 1
}

// Cluster needs sorting of content, by date

function sortDivs () {
  let i
  const sortlist = document.getElementById('popup-content')
  const divs = sortlist.getElementsByTagName('div')
  const listitems = []
  for (i = 0; i < divs.length; i++) {
    listitems.push(divs.item(i))
  }
  listitems.sort(function (a, b) {
    const compA = a.getAttribute('id')
    const compB = b.getAttribute('id')
    return (compA < compB) ? -1 : (compA > compB) ? 1 : 0
  })
  for (i = 0; i < listitems.length; i++) {
    sortlist.appendChild(listitems[i])
  }
};
