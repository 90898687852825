// Progressbar
export function Progress(el) {
    this.el = el;
    this.loading = 0;
    this.loaded = 0;
  }
  
  Progress.prototype.addLoading = function () {
    if (this.loading === 0) {
      this.show();
    }
    ++this.loading;
    this.update();
  };
  
  Progress.prototype.addLoaded = function () {
    var this_ = this;
    setTimeout(function () {
      ++this_.loaded;
      this_.update();
    }, 100);
  };
  
  Progress.prototype.update = function () {
    var width = ((this.loaded / this.loading) * 100).toFixed(1) + '%';
    this.el.style.width = width;
    if (this.loading === this.loaded) {
      this.loading = 0;
      this.loaded = 0;
      var this_ = this;
      setTimeout(function () {
        this_.hide();
      }, 500);
    }
  };
  
  Progress.prototype.show = function () {
    this.el.style.visibility = 'visible';
  };
  
  Progress.prototype.hide = function () {
    if (this.loading === this.loaded) {
      this.el.style.visibility = 'hidden';
      this.el.style.width = 0;
    }
  };